import React from 'react';
import cx from 'classnames';
import Amplitude from 'amplitude-js';
import qs from 'query-string';

import {HomeIcon, PlayIcon, SubmitIcon, ShareIcon} from './components/icons';
import './App.css';

import Content from './content/facebook-debugger';

const AMPLITUDE_KEY = 'f6e11f925f96de1d333c76bbea0ed9e9';

class App extends React.Component {
  BUTTON_DELAY = 5; // Show choices for last 5 sec
  videoElement;

  constructor(props) {
    super(props);
    this.state = {
      started: false,
      activeKey: 'start',
      isMobileDevice: this.isMobileDevice(),
      history: {},
      shareOpen: false,
    };
  }

  isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  isSafari = () => {
    return /Safari/i.test(navigator.userAgent) && !/Chrome/i.test(navigator.userAgent);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyEvents);
    document.addEventListener('keyup', this.handleKeyEvents);
    document.addEventListener('keypress', this.handleKeyEvents);

    this.initTracking();
    this.logEvent('View Page');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeKey !== this.state.activeKey) {
      this.setupVideo();
    }
  }

  initTracking = () => {
    Amplitude.getInstance().init(AMPLITUDE_KEY, null, {
      saveEvents: true,
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });
  }

  logEvent = (name, data) => {
    try {
      if (!name) return;
      const path = window.location.pathname;
      const {query} = qs.parseUrl(window.location.search);
      const eventData = {...data, path, query: {...query}};
      Amplitude.getInstance().logEvent(name, eventData);
      console.log(`Logged: ${name}, ${JSON.stringify(data)}`);
    } catch (err) {
      console.error(err);
    }
  }

  start = () => {
    if (this.isSafari()) {
      // Due to Safari permissions we have to play this directly
      this.videoElement = document.getElementById('player');
      this.videoElement.play();
      this.setState({showOverlay: true}, () => {
        setTimeout(() => {
          this.setState({
            started: true,
            showOverlay: false,
          });
          this.setupVideo(false);
        }, 250);
      });
      return;
    }

    this.setState({showOverlay: true}, () => {
      setTimeout(() => {
        this.setState({
          started: true,
          showOverlay: false,
        });
        this.setupVideo();
      }, 1000);
    });

    this.logEvent("Clicked Start");
  }

  setupVideo = (play = true) => {
    this.videoElement = document.getElementById('player');

    // This attempts to autoplay the video and listen for duration changes
    if (this.videoElement) {
      if (play) this.videoElement.play();
      this.videoElement.addEventListener('timeupdate', this.handleTimeUpdate);
      this.videoElement.addEventListener('playing', () => {
        this.setState({
          selectedChoice: false,
          showingChoices: false,
          showOverlay: false,
        });
      });
    }
  }

  handleTimeUpdate = (event) => {
    const {target} = event;

    if (!target || !target.duration || !target.currentTime) {
      console.error("Duration or timestamp missing from timeupdate event");
    }

    const duration = target.duration;
    const currentTime = target.currentTime;
    const shouldShowChoices = duration - currentTime <= this.BUTTON_DELAY;
    const {showingChoices} = this.state;

    if (!showingChoices && shouldShowChoices) {
      this.setState({showingChoices: true});
    } else if (showingChoices && !shouldShowChoices) {
      this.setState({showingChoices: false});
    }
  }

  goToVideo = (id) => {
    if (id === 'share') {
      this.toggleShare();
      return;
    }

    document.getElementById('player').pause();
    const history = this.state.history;
    history[id] = true;
  
    this.setState({
      activeKey: id,
      showingChoices: false,
      selectedChoice: true,
      showOverlay: true,
      history,
    });

    this.logEvent("Clicked Choice", {
      active: this.state.activeKey,
      choice: id,
    });
  }

  getVideoPreloader = (id, preload) => {
    const videoURL = Content[id] ? Content[id].video : null;
    return videoURL && (
      <video
        key={id}
        preload={preload ? "auto" : "none"}
      >
        <source src={preload ? videoURL : ""} type="video/mp4" />
      </video>
    );
  }

  handleKeyEvents = (e) => {
    // We allow video controls to be used while the Cmd key is pressed
    if (e.type === 'keydown' && e.key === 'Meta') {
      this.setState({showControls: true});
    } else if (e.type === 'keyup' && e.key === 'Meta') {
      this.setState({showControls: false});
    } else if (e.type === 'keypress' && e.key === 'Enter') {
      if (!this.state.started) {
        this.start();
      }
    }
  }

  toggleShare = () => {
    const shareOpen = !this.state.shareOpen;

    this.setState({shareOpen});

    if (shareOpen) {
      this.logEvent('Started Share', {
        active: this.state.activeKey,
        started: this.state.started,
      });
    }
  }

  onClickFacebook = () => {
    const facebookURL = 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsimulator.tryexponent.com%2F%3Futm_source%3Dfacebook%26utm_medium%3Dsocial%26utm_campaign%3Dsimulator&amp;src=sdkpreparse'
    window.open(facebookURL, 'facebook', 'height=500,width=550,resizable=1');
    this.logEvent('Clicked Share', {
      type: 'facebook',
      active: this.state.activeKey,
      started: this.state.started,
    });
  }

  onClickTwitter = () => {
    const text = 'Check out this Interview Simulator from @tryexponent! #prodmgmt https://simulator.tryexponent.com/?utm_source=twitter&utm_medium=social&utm_campaign=simulator';
    const twitterURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`;
    window.open(twitterURL);
    this.logEvent('Clicked Share', {
      type: 'twitter',
      active: this.state.activeKey,
      started: this.state.started,
    });
  }

  onClickLinkedin = () => {
    const url = 'https://simulator.tryexponent.com/?utm_source=linkedin&utm_medium=social&utm_campaign=simulator';
    const linkedinURL = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}`;
    window.open(linkedinURL, 'linkedin', 'height=500,width=550,resizable=1');
    this.logEvent('Clicked Share', {
      type: 'linkedin',
      active: this.state.activeKey,
      started: this.state.started,
    });
  }

  copyLink = () => {
    navigator.clipboard.writeText('https://simulator.tryexponent.com/?utm_source=share&utm_campaign=simulator');
    this.setState({linkCopied: true});
    this.logEvent('Clicked Share', {
      type: 'link',
      active: this.state.activeKey,
      started: this.state.started,
    });
  }

  render() {
    const {
      started,
      activeKey,
      showingChoices,
      selectedChoice,
      showOverlay,
      showControls,
      isMobileDevice,
      history,
      shareOpen,
    } = this.state;

    const showChoices = showingChoices && !selectedChoice;
    const activeContent = Content[activeKey];
  
    return (
      <div className="App">
        <div className={cx('App__overlay', {'App__overlay--visible': showOverlay})} />

        {isMobileDevice && (
          <p className="App__mobile">
            Looks like you're using a mobile device. We recommend using
            a computer for the best experience! Trust us, it's worth it.
          </p>
        )}
        
        <a
          className="App__home"
          href="https://www.tryexponent.com/?utm_source=simulator&utm_campaign=home"
          target="_blank"
        >
          <HomeIcon />
          Exponent
        </a>

       



        {!shareOpen && (
          <button className="App__share" onClick={this.toggleShare}>
            <ShareIcon />
            Share
          </button>
        )}
        
        {!isMobileDevice && !started && (
          <a 
            className="App__ph"
            href="https://www.producthunt.com/posts/interview-simulator?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-interview-simulator" 
            target="_blank"
          >
            <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=223262&theme=dark" alt="Interview Simulator - Prepare for interviews with a choose-your-own-adventure game | Product Hunt Embed" style={{width: 200, height: 45}} width="200px" height="45px" />
          </a>
        )}


        {(!isMobileDevice && !started) ? (
          <div className="App__start-container">
            <h1>Interview Simulator</h1>
            <button className="App__start" onClick={this.start}>
              <PlayIcon width={25} height={25} />
              Start Interview
            </button>
            <span className="App__start-helper-text">
              or press Enter <SubmitIcon fill="#666666" height={12} width={12} />
            </span>
            <span className="App__made-by">
              Made by <a target="_blank" href="https://tryexponent.com/?utm_source=simulator">Exponent</a>
            </span>
          </div>
        ) : null}

        <div className={cx("App__content", {'App__content--visible': started})}>
          <div className="App__video-container">
            <video
              key={activeKey}
              id="player"
              width="100%"
              controls={showControls}
              className="App__video"
              preload="auto"
            >
              <source src={activeContent.video} type="video/mp4" />
            </video>
            <div className="App__video-overlay" />
          </div>
          <div className="App__choices">
            {showChoices && activeContent.choices.map((choice) => (
              choice.to ? (
                <button
                  className={cx('App__choice', {
                    'App__choice--visited': history[choice.to],
                  })}
                  key={choice.to}
                  onClick={() => this.goToVideo(choice.to)}
                  style={choice.style ? choice.style : undefined}
                >
                  {choice.text}
                </button>
              ) : (
                <a
                  href={choice.href}
                  className="App__choice"
                  key={choice.to}
                  style={choice.style ? choice.style : undefined}
                  target="_blank"
                >
                  {choice.text}
                </a>
              )
            ))}
          </div>
        </div>

        <div className="App__video-preloader" style={{display: 'none'}}>
          {activeContent.choices.map(({to}) => this.getVideoPreloader(to, true))}
        </div>

        {shareOpen && (
          <div className="App__share-overlay">
            <button className="App__share" onClick={this.toggleShare}>
              Close
            </button>
            <div className="App__share-choices">
              <button
                className="App__share-choice"
                onClick={this.onClickFacebook}
              >
                Facebook
              </button>
              <button
                className="App__share-choice"
                onClick={this.onClickTwitter}
              >
                Twitter
              </button>
              <button
                className="App__share-choice"
                onClick={this.onClickLinkedin}
              >
                LinkedIn
              </button>
              <button
                className="App__share-choice"
                onClick={this.copyLink}
              >
                {this.state.linkCopied ? 'Copied!' : 'Copy Link'}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default App;
