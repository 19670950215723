import React from 'react';

export const PlayIcon = ({className, height = '30', width = '30'}) => (
  <svg
    width={width}
    height={height}
    viewBox="8 8 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9531 14.5152C19.4654 14.7741 19.4654 15.5056 18.9531 15.7646L13.0158 18.7662C12.5502 19.0016 12 18.6632 12 18.1415L12 12.1383C12 11.6165 12.5502 11.2782 13.0158 11.5136L18.9531 14.5152Z"
      fill="white"
    />
  </svg>
);

export const SubmitIcon = ({height = 20, width = 20, fill = '#ffffff'}) => (
  <svg height={height} width={width} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
      <g id="icon-shape">
        <polygon id="Combined-Shape" points="18 12 18 13 8 13 8 18 2 12 8 6 8 11 16 11 16 2 18 2"></polygon>
      </g>
    </g>
  </svg>
);

export const ShareIcon = ({fill = '#ffffff', height = 20, width = 20}) => (
  <svg viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
      <g id="icon-shape">
        <path d="M9,3.82842712 L9,8 L8,8 L8,10 L6,10 L6,18 L14,18 L14,10 L12,10 L12,8 L11,8 L11,16 L9,16 L9,8 L6.0085302,8 C4.90195036,8 4,8.8992496 4,10.0085302 L4,17.9914698 C4,19.0980496 4.8992496,20 6.0085302,20 L13.9914698,20 C15.0980496,20 16,19.1007504 16,17.9914698 L16,10.0085302 C16,8.90195036 15.1007504,8 13.9914698,8 L11,8 L11,3.82842712 L14.0710678,6.89949494 L15.4852814,5.48528137 L10.7071068,0.707106781 L10,4.18784448e-14 L4.51471863,5.48528137 L5.92893219,6.89949494 L9,3.82842712 Z" id="Combined-Shape"></path>
      </g>
    </g>
  </svg>
);

export const LinkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path d="M9.26 13a2 2 0 0 1 .01-2.01A3 3 0 0 0 9 5H5a3 3 0 0 0 0 6h.08a6.06 6.06 0 0 0 0 2H5A5 5 0 0 1 5 3h4a5 5 0 0 1 .26 10zm1.48-6a2 2 0 0 1-.01 2.01A3 3 0 0 0 11 15h4a3 3 0 0 0 0-6h-.08a6.06 6.06 0 0 0 0-2H15a5 5 0 0 1 0 10h-4a5 5 0 0 1-.26-10z">
    </path>
  </svg>
);

export const HomeIcon = ({fill = '#ffffff', height = 12, width = 12}) => (
  <svg viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
      <g id="icon-shape">
        <path d="M17,10 L20,10 L10,0 L0,10 L3,10 L3,20 L17,20 L17,10 Z M8,14 L12,14 L12,20 L8,20 L8,14 Z"></path>
      </g>
    </g>
  </svg>
);