import React from 'react';
import {ShareIcon} from '../components/icons';

const content = {
  "start": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_welcome_%281%29_262b03e9f4.mp4",
    "choices": [{
      "text": "Investigate Seasonality",
      "to": "seasonality"
    }, {
      "text": "Analyze Demographics",
      "to": "demographics"
    }, {
      "text": "Check on Platform Updates",
      "to": "platform-updates"
    }],
  },
  "seasonality": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_1_seasonality_48f50e47da.mp4",
    "choices": [
      {
      "text": "Check if the pattern happens the same day last year",
      "to": "same-day"
      },
      {
      "text": "Check news cycles",
      "to": "news"
      },
      {
      "text": "Check if there are similar patterns generally",
      "to": "similar-patterns"
      },
    ],
  },
  "same-day": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_11_same_day_-_compressed_1205cd5d88.mp4",
    "choices": [
      {
      "text": "Check news cycles",
      "to": "demographics"
      },
      {
      "text": "Check if there are similar patterns generally",
      "to": "similar-patterns"
      },
    ],
  },
  "news": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_12_news_b186e6b3ab.mp4",
    "choices": [
      {
      "text": "Check if the pattern happens the same day last year",
      "to": "same-day"
      },
      {
      "text": "Check if there are similar patterns generally",
      "to": "similar-patterns"
      },
    ],
  },
  "similar-patterns": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_13_similar_declines_-_compressed_8034bd18d4.mp4",
    "choices": [
      {
      "text": "Analyze Demographics",
      "to": "demographics"
      },
      {
      "text": "Check on Platform Updates",
      "to": "platform-updates"
      },
    ],
  },
  "demographics": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_2_demographics_34ab2fa51e.mp4",
    "choices": [{
      "text": "Age",
      "to": "age"
    }, {
      "text": "Gender",
      "to": "gender"
    }, {
      "text": "Location",
      "to": "location"
    }, {
      "text": "🐶",
      "to": "dog",
      "style": {fontSize: 24},
    }],
  },
  "dog": {
    "video": "https://exponent-content.s3.amazonaws.com/Snatch_Dogs_%281%29_3e8a4d19f4.mp4",
    "choices": [{
      "text": "Age",
      "to": "age"
    }, {
      "text": "Gender",
      "to": "gender"
    }, {
      "text": "Location",
      "to": "location"
    },
    ],
  },
  "platform-updates": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_3_platform_2a2a662560.mp4",
    "choices": [{
      "text": "Operating System",
      "to": "os"
    }, {
      "text": "App",
      "to": "app"
    }, {
      "text": "Hardware",
      "to": "hardware"
    }],
  },
  "os": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_31_OS_%281%29_33e9567dab.mp4",
    "choices": [{
      "text": "App",
      "to": "app"
    }, {
      "text": "Hardware",
      "to": "hardware"
    }],
  },
  "hardware": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_33_hardware_%281%29_e6cd7f9044.mp4",
    "choices": [{
      "text": "App",
      "to": "app"
    }, {
      "text": "OS",
      "to": "os"
    }],
  },
  "app": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_32_app_a3d7a55709.mp4",
    "choices": [{
      "text": "Slice by version number",
      "to": "slice-version"
    }, {
      "text": "Check bug reports",
      "to": "bug-report"
    }, {
      "text": "Crash loop",
      "to": "crash-loop",
    }],
  },
  "crash-loop": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_crashloop_-_compressed_68c6c56b08.mp4",
    "choices": [{
      "text": "Crash loop",
      "to": "crash-loop2"
    }],
  },
  "crash-loop2": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_crashloop_-_compressed_68c6c56b08.mp4",
    "choices": [{
      "text": "Try again",
      "to": "crash-loop3"
    }],
  },
  "crash-loop3": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_crashloop_-_compressed_68c6c56b08.mp4",
    "choices": [{
      "text": "I'm frustrated",
      "to": "frustrated"
    }],
  },
  "frustrated": {
    "video": "https://exponent-content.s3.amazonaws.com/Snatch_Frustrated-chairpull_261c623844.mp4",
    "choices": [{
      "text": "Return",
      "to": "return-app"
    }],
  },
  "slice-version": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_321_slice_%281%29_9fcbd9561f.mp4",
    "choices": [
      {
      "text": "Analyze Demographics",
      "to": "demographics"
      },
      {
      "text": "Check for Seasonality",
      "to": "seasonality"
      },
    ],
  },
  "return-app": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_errorpath2_1832f804d0.mp4",
    "choices": [{
      "text": "Slice by version number",
      "to": "slice-version"
    }, {
      "text": "Check bug reports",
      "to": "bug-report"
    }, {
      "text": "Crash loop",
      "to": "crash-loop",
    }],
  },
  "bug-report": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_322_bugreport_-_compressed_6edd4762f5.mp4",
    "choices": [{
      "text": "Slice by version number",
      "to": "slice-version"
    },],
  },
  "age": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_21_age_cd4738d02c.mp4",
    "choices": [{
      "text": "Gender",
      "to": "gender"
    }, {
      "text": "Location",
      "to": "location"
    }],
  },
  "gender": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_22_gender_977fe1e55c.mp4",
    "choices": [{
      "text": "Age",
      "to": "age"
    }, {
      "text": "Location",
      "to": "location"
    }],
  },
  "location": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_23_location_44be42943d.mp4",
    "choices": [{
      "text": "Internal Causes",
      "to": "internal"
    }, {
      "text": "External Causes",
      "to": "external"
    }],
  },
  "data-center": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_2311_data_center_-_compressed_53faab48b9.mp4",
    "choices": [{
      "text": "External factors",
      "to": "external"
    }, {
      "text": "Logging issues",
      "to": "logging"
    }],
  },
  "logging": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_2312_logging_%281%29_b079b93fca.mp4",
    "choices": [{
      "text": "External factors",
      "to": "external"
    }, {
      "text": "Data center outage",
      "to": "data-center"
    }],
  },
  "internal": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_231_internal_953ba685cf.mp4",
    "choices": [{
      "text": "Data center outage",
      "to": "data-center"
    }, {
      "text": "Logging issues",
      "to": "logging"
    }],
  },
  "external": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_232_external_c1f0346223.mp4",
    "choices": [{
      "text": "Local competition",
      "to": "competition"
    }, {
      "text": "Network issues",
      "to": "network"
    }],
  },
  "competition": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_2321_competitor_-_compressed_451b2f531e.mp4",
    "choices": [{
      "text": "Network issues",
      "to": "network"
    }],
  },
  "network": {
    "video": "https://exponent-content.s3.amazonaws.com/Sequence_2322_carrier_%28final%29_%281%29_31c7ca172c.mp4",
    "finished": true,
    "choices": [{
      "text": "Visit Exponent",
      "href": "https://tryexponent.com/?utm_source=simulator",
    },  {
      // This is a special option that triggers the sharing modal
      "text": <span className="ShareOption"><ShareIcon /> Share</span>,
      "to": "share",
    }],
  },
};

export default content;
